import React from 'react';
import linkBottom from "../assets/link-bottom.svg";
import {cleanString} from "../shared/Constants";
import linkTop from "../assets/link-top.svg";
import addIcon from "../assets/add_box_FILL1_wght400_GRAD0_opsz24.svg";

function GoalClass({ index, goalJSON, showAddNutritionGoalModal }) {
    console.log("goalJSON:");
    console.log(JSON.stringify(goalJSON, null, 2));
    return (
        <div className="exercise-cell">
            <div className={"exerciseCellRightDiv exerciseCellRightDivPadding "}>
                <div className="setsReps">
                    <div className="sets-cell" style={{textAlign: "center"}}>
                        <div className="nutrition-goal-headers">CALORIES</div>
                        <div style={{color: "green"}}>{goalJSON.g.cal}</div>
                    </div>
                    <div className="sets-cell" style={{textAlign: "center"}}>
                        <div className="nutrition-goal-headers">CARB</div>
                        <div style={{color: "var(--color-exerprise-system-blue)"}}>{goalJSON.g.c}g</div>
                    </div>
                    <div className="sets-cell" style={{textAlign: "center"}}>
                        <div className="nutrition-goal-headers">PROTEIN</div>
                        <div style={{color: "var(--color-exerprise-system-blue)"}}>{goalJSON.g.p}g</div>
                    </div>
                    <div className="sets-cell" style={{textAlign: "center"}}>
                        <div className="nutrition-goal-headers">FAT</div>
                        <div style={{color: "var(--color-exerprise-system-blue)"}}>{goalJSON.g.f}g</div>
                    </div>
                </div>
                <div className="setsReps" style={{justifyContent: "center"}}>
                    <div style={{color: "var(--color-exerprise-orange)"}}>{goalJSON.s}</div> <a style={{color: "black"}}>&nbsp;-&nbsp;</a> <div style={{color: "var(--color-exerprise-orange)"}}>{goalJSON.e}</div>
                </div>
                <div className="rightSubText">
                    <img src={addIcon} onClick={() => showAddNutritionGoalModal(index)} className="filter-system-blue swap-icon" alt={"add icon"}/>
                </div>
                <div className="exercise-separater"></div>
            </div>
        </div>
    );
}

export default GoalClass;