import React from 'react';
import addIcon from "../assets/add_box_FILL1_wght400_GRAD0_opsz24.svg";

function AddNutritionGoalModal({setShowAddNutritionGoalModalFlag,calories,setCalories,fat,setFat,carbs,setCarbs,protein,setProtein,addNutritionGoal, startDate, setStartDate, endDate, setEndDate, nutritionIndex}) {
    function closeModal(){
        setShowAddNutritionGoalModalFlag(false);
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Enter goal values:</a>
                    {/*<input className="exerciseName" style={{marginTop: "20px", marginBottom: "20px"}} size={49} value={calories} onChange={e => setCalories(e.target.value)} />*/}
                    <div className={"exerciseCellRightDiv exerciseCellRightDivPadding "}>
                        <div className="setsReps" style={{width: "400px", paddingBottom: "10px"}}>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">CALORIES</div>
                                {/*<div style={{color: "green"}}>2905</div>*/}
                                <input style={{marginTop: "10px", color: "green", textAlign: "center", fontWeight: "bold"}} size={5} pattern={"[0-9]*"} value={calories} onChange={e => setCalories(e.target.value)} />
                            </div>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">CARB (g)</div>
                                {/*<div style={{color: "var(--color-exerprise-system-blue)"}}>290.5g</div>*/}
                                <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={carbs} onChange={e => setCarbs(e.target.value)} />
                            </div>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">PROTEIN (g)</div>
                                {/*<div style={{color: "var(--color-exerprise-system-blue)"}}>290.5g</div>*/}
                                <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={protein} onChange={e => setProtein(e.target.value)} />
                            </div>
                            <div className="sets-cell" style={{textAlign: "center"}}>
                                <div className="nutrition-goal-headers">FAT (g)</div>
                                <input style={{marginTop: "10px", color: "var(--color-exerprise-system-blue)", textAlign: "center", fontWeight: "bold"}} size={5} value={fat} onChange={e => setFat(e.target.value)} />
                            </div>
                        </div>
                        <div className="setsReps" style={{justifyContent: "center", marginBottom: "20px", marginTop: "10px"}}>
                            <div style={{color: "var(--color-exerprise-orange)"}}>Start date:&nbsp;&nbsp;</div>
                            <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                        </div>
                        <div className="setsReps" style={{justifyContent: "center", marginBottom: "20px"}}>
                            <div style={{color: "var(--color-exerprise-orange)"}}>End date:&nbsp;&nbsp;</div>
                            <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                        </div>
                    </div>
                    <button className="normal-button-system" onClick={addNutritionGoal} style={{width: "140px", margin: "auto", marginBottom: "12px"}}>Add Goal</button>
                </div>
            </div>
        </div>
    );
}

export default AddNutritionGoalModal;